<template>
  <div class="face-lines-slider">
    <div class="heading face-lines-slider__heading">
      <span class="heading__number">
        {{ groupNumber }}
      </span>
      <span class="heading__title">{{ $t(title) }}</span>
    </div>
    <u-icon class="face-lines-slider__close-icon" name="close" @click="onClose" />
    <div class="face-lines-slider__slider slider">
      <div class="slider__titles titles">
        <span
          v-for="option of $options.sliderOptions"
          :key="option.title"
          class="titles__value"
          @click="onInput(option.value)"
        >
          {{ $t(option.title) }}
        </span>
      </div>
      <input
        class="slider__input"
        type="range"
        min="1"
        max="3"
        step="1"
        :value="value"
        @input="onInput($event.target.value)"
      />
    </div>
  </div>
</template>

<script>
import { UIcon } from 'universkin-shared';

const SLIDER_OPTIONS = [
  { title: 'depth.shallow', value: '1' },
  { title: 'depth.visible', value: '2' },
  { title: 'depth.deep', value: '3' }
];

export default {
  name: 'FaceLinesSlider',
  components: { UIcon },
  sliderOptions: SLIDER_OPTIONS,
  props: {
    value: {
      type: String,
      required: true
    },
    groupNumber: {
      type: [Number, String],
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';

@mixin slider-thumb {
  box-shadow: none;
  border: none;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: $color-grey900;
  cursor: pointer;
}

@mixin slider-track {
  background-color: $color-grey100;
  background-image: url(~@/modules/questionnaire/assets/images/face-lines-select/face-lines-slider-track.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 13px;
  width: 100%;
  cursor: pointer;
  box-shadow: none;
  border-radius: 2px;
  border: none;
}

.heading {
  display: flex;
  align-items: center;

  &__number {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-size: 16px;
    line-height: 19px;
    margin-left: 8px;
  }
}

.face-lines-slider {
  box-sizing: border-box;
  width: 217px;
  font-family: var(--u-font-family-Gilroy);
  font-style: normal;
  font-weight: normal;
  color: $color-grey700;
  background-color: $color-grey100;
  border-radius: 10px;
  padding: 16px 26px 16px 16px;
  position: relative;

  &__close-icon {
    position: absolute;
    --u-icon-color: #{$color-grey400};
    top: 13px;
    right: 13px;
    cursor: pointer;
  }

  &__slider {
    margin-top: 16px;
  }
}

.slider {
  &__titles {
    margin-bottom: 7px;
  }

  &__input,
  &__input:focus {
    -webkit-appearance: none;
    margin: 18px 0;
    width: 100%;
    height: 7px;
    outline: none;

    &::-webkit-slider-thumb {
      @include slider-thumb;
      -webkit-appearance: none;
      margin-top: -4px;
    }

    &::-moz-range-thumb {
      @include slider-thumb;
      transform: translateY(-5px);
    }

    &::-webkit-slider-runnable-track {
      @include slider-track;
    }

    &::-moz-range-track {
      @include slider-track;
    }
  }
}

.titles {
  display: flex;
  justify-content: space-between;

  &__value {
    font-size: 14px;
    line-height: 120%;
    color: $color-grey500;
    cursor: pointer;
  }
}
</style>

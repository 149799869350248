<template>
  <div class="face-lines-picker">
    <img class="face-lines-picker__face" usemap="#face-map" :src="faceImage" />
    <img
      class="face-lines-picker__wrinkles"
      usemap="#face-map"
      src="@/modules/questionnaire/assets/images/face-lines-select/lines/all-inactive.svg"
    />
    <img
      v-for="activeGroupImage of activeGroupImages"
      :key="activeGroupImage"
      class="face-lines-picker__active-group"
      usemap="#face-map"
      :src="activeGroupImage"
    />
    <img
      v-if="hoverImage"
      class="face-lines-picker__active-group"
      usemap="#face-map"
      :src="hoverImage"
    />
    <map name="face-map">
      <area
        v-for="zone of imageMapAreas"
        :key="zone.key"
        class="face-lines-picker__zone-area"
        shape="poly"
        :coords="coords[zone.groupName][zone.zoneIndex]"
        :data-face-area-id="zone.groupName"
        @click="onClick"
        @mouseenter="onMouseEnterZone(zone.groupName)"
        @mouseleave="onMouseLeaveZone"
      />
    </map>
  </div>
</template>

<script>
import { kebabCase } from 'lodash';

import { doesMatchMedia } from '@/utils/screenSize';
import { isTouchDevice } from '@/utils/featureDetection';

import { FACE_LINES_COORDS } from '@/modules/questionnaire/new-design-components/questions/face-lines-input/faceLinesCoords';

export default {
  name: 'FaceLinesPicker',
  props: {
    value: {
      type: Object,
      required: true
    },
    gender: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      coords: FACE_LINES_COORDS.SMALL,
      hoverImage: null
    };
  },
  computed: {
    faceImage() {
      return require(`@/modules/questionnaire/assets/images/face-lines-select/face-image/${this.gender}.svg`);
    },
    activeGroupImages() {
      return Object.entries(this.value)
        .filter(([, value]) => +value > 0)
        .map(([groupName, value]) => this.getActiveGroupImage({ groupName, value }));
    },
    imageMapAreas() {
      return Object.entries(this.coords).reduce((acc, [groupName, coords]) => {
        coords.forEach((coord, zoneIndex) => {
          acc.push({ groupName, zoneIndex, key: `zone-${groupName}-${zoneIndex}` });
        });

        return acc;
      }, []);
    },
    shouldHandleHoverEvents() {
      return !isTouchDevice();
    }
  },
  mounted() {
    this.updateCoords();

    window.addEventListener('resize', this.updateCoords);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateCoords);
  },
  methods: {
    onMouseEnterZone(groupName) {
      if (this.shouldHandleHoverEvents) {
        this.hoverImage = this.getHoverImage(groupName);
      }
    },
    onMouseLeaveZone() {
      this.hoverImage = null;
    },
    updateCoords() {
      const isDesktop = doesMatchMedia('(min-width: 960px)');

      this.coords = isDesktop ? FACE_LINES_COORDS.MEDIUM : FACE_LINES_COORDS.SMALL;
    },
    onClick(event) {
      const groupName = event.target.dataset.faceAreaId;
      const isGroupActive = +this.value[groupName] > 0;

      if (isGroupActive) {
        const wrinklesWithInactiveGroup = {
          ...this.value,
          [groupName]: '0'
        };
        this.$emit('input', wrinklesWithInactiveGroup);

        return;
      }

      const wrinkles = {
        ...this.value,
        [groupName]: '1'
      };

      this.$emit('input', wrinkles);
    },
    getActiveGroupImage({ groupName, value }) {
      const folder = kebabCase(groupName);
      const file = `${value}.svg`;

      return require(`@/modules/questionnaire/assets/images/face-lines-select/lines/active/${folder}/${file}`);
    },
    getHoverImage(groupName) {
      const folder = kebabCase(groupName);

      return require(`@/modules/questionnaire/assets/images/face-lines-select/lines/active/${folder}/1.svg`);
    }
  }
};
</script>

<style lang="scss" scoped>
.face-lines-picker {
  width: 144px;
  height: 259px;

  position: relative;

  &__face,
  &__wrinkles,
  &__active-group {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__face {
    z-index: 1;
  }

  &__wrinkles {
    z-index: 2;
  }

  &__active-group {
    z-index: 3;
  }

  &__zone-area {
    cursor: pointer;
  }
}

@media (min-width: 960px) {
  .face-lines-picker {
    width: 198px;
    height: 356px;
  }
}
</style>

<template>
  <div class="facial-features q-w940 q-fit">
    <question-with-error-wrap :error="getFieldsError(['wrinkles'])">
      <face-lines-input
        :gender="gender"
        :value="wrinkles"
        @input="onFieldChange('wrinkles', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import FaceLinesInput from '@/modules/questionnaire/new-design-components/questions/face-lines-input/FaceLinesInput';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

export default {
  name: 'WrinkleLocationsTemplate',
  components: { FaceLinesInput, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  props: {
    wrinkles: {
      type: Object,
      required: true
    },
    gender: {
      type: String,
      default: ''
    }
  }
};
</script>

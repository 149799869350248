import { WRINKLES_GROUP } from '@/modules/questionnaire/api/constants';

const ZOOM_FACTOR = 0.73;

/* Polygon-shape areas defined by lists of (x,y) points. */
const RAW_COORDS = {
  [WRINKLES_GROUP.FORHEAD]: [[44, 73, 148, 73, 148, 96, 44, 96]],
  [WRINKLES_GROUP.FROWN]: [[91, 108, 107, 108, 107, 133, 91, 133]],
  [WRINKLES_GROUP.CROWS_FEET]: [
    [19, 132, 34, 132, 34, 153, 19, 153],
    [163, 133, 178, 133, 178, 157, 163, 157]
  ],
  [WRINKLES_GROUP.BUNNY]: [
    [57, 165, 80, 165, 80, 181, 57, 181],
    [117, 165, 141, 165, 141, 183, 117, 183]
  ],
  [WRINKLES_GROUP.TEAR_TROUGHS]: [
    [36, 154, 78, 154, 78, 163, 36, 163],
    [119, 154, 161, 154, 161, 162, 119, 162]
  ],
  [WRINKLES_GROUP.NASOLABIAL_FOLDS]: [
    [55, 190, 77, 190, 77, 209, 55, 209],
    [119, 190, 137, 190, 137, 211, 119, 211]
  ],
  [WRINKLES_GROUP.VERTICAL_LIP]: [[79, 203, 116, 203, 116, 214, 79, 214]],
  [WRINKLES_GROUP.MOUTH_FROWN]: [
    [57, 215, 71, 215, 71, 224, 57, 224],
    [125, 215, 138, 215, 138, 224, 125, 224]
  ],
  [WRINKLES_GROUP.MARIONETTE]: [
    [61, 226, 70, 226, 70, 250, 61, 250],
    [125, 224, 136, 224, 136, 248, 125, 248]
  ],
  [WRINKLES_GROUP.CHIN_CREASE]: [[76, 247, 119, 247, 119, 259, 76, 259]],
  [WRINKLES_GROUP.NECK]: [[44, 284, 149, 284, 149, 324, 44, 324]]
};

export const FACE_LINES_COORDS = Object.entries(RAW_COORDS).reduce(
  (acc, [groupNumber, coords]) => {
    acc.MEDIUM[groupNumber] = [];
    acc.SMALL[groupNumber] = [];

    coords.forEach(zone => {
      acc.MEDIUM[groupNumber].push(zone.join(','));
      acc.SMALL[groupNumber].push(zone.map(n => n * ZOOM_FACTOR).join(','));
    });

    return acc;
  },
  { MEDIUM: {}, SMALL: {} }
);

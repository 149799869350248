<template>
  <div ref="sliderSet" class="slider-set full-width">
    <span
      v-if="isEmpty"
      class="slider-set__empty-message u-typography-helvetica u-text u-text--xs"
      >{{ $t('wrinkleLocation.setTheDeepness') }}</span
    >
    <template v-if="isDesktopLayout">
      <face-lines-slider
        v-for="linesGroup of lineGroups"
        :key="linesGroup.groupNumber"
        class="slider-set__slider"
        :group-number="linesGroup.groupNumber"
        :value="linesGroup.value"
        :title="linesGroup.groupTitle"
        @input="onValueChange(linesGroup.groupNumber, $event)"
        @close="onValueChange(linesGroup.groupNumber, '0')"
      />
    </template>
    <vue-carousel
      v-else
      class="carousel full-width"
      :items="lineGroups"
      :current-item="currentGroupIndex"
      :visible-at-a-time="visibleAtATime"
      :speed="1"
      @carousel-move="updateCurrentItem"
    >
      <template #item="{ item: linesGroup }">
        <face-lines-slider
          class="carousel__item"
          :group-number="linesGroup.groupNumber"
          :value="linesGroup.value"
          :title="linesGroup.groupTitle"
          @input="onValueChange(linesGroup.groupNumber, $event)"
          @close="onValueChange(linesGroup.groupNumber, '0')"
        />
      </template>
    </vue-carousel>
  </div>
</template>

<script>
import { clamp, some } from 'lodash';
import VueCarousel from 'vue-carousel-generic';
import PerfectScrollbar from 'perfect-scrollbar';

import FaceLinesSlider from '@/modules/questionnaire/new-design-components/questions/face-lines-input/FaceLinesSlider';

import { doesMatchMedia } from '@/utils/screenSize';

import { WRINKLES_GROUP } from '@/modules/questionnaire/api/constants';

const SLIDES_PER_VIEW = {
  medium: [1, 1, 1],
  small: [1, 0.5]
};

const GROUP_NUMBER = {
  [WRINKLES_GROUP.FORHEAD]: 1,
  [WRINKLES_GROUP.FROWN]: 2,
  [WRINKLES_GROUP.CROWS_FEET]: 3,
  [WRINKLES_GROUP.TEAR_TROUGHS]: 4,
  [WRINKLES_GROUP.BUNNY]: 5,
  [WRINKLES_GROUP.NASOLABIAL_FOLDS]: 6,
  [WRINKLES_GROUP.VERTICAL_LIP]: 7,
  [WRINKLES_GROUP.MOUTH_FROWN]: 8,
  [WRINKLES_GROUP.MARIONETTE]: 9,
  [WRINKLES_GROUP.CHIN_CREASE]: 10,
  [WRINKLES_GROUP.NECK]: 11
};

const GROUP_NAME = {
  1: [WRINKLES_GROUP.FORHEAD],
  2: [WRINKLES_GROUP.FROWN],
  3: [WRINKLES_GROUP.CROWS_FEET],
  4: [WRINKLES_GROUP.TEAR_TROUGHS],
  5: [WRINKLES_GROUP.BUNNY],
  6: [WRINKLES_GROUP.NASOLABIAL_FOLDS],
  7: [WRINKLES_GROUP.VERTICAL_LIP],
  8: [WRINKLES_GROUP.MOUTH_FROWN],
  9: [WRINKLES_GROUP.MARIONETTE],
  10: [WRINKLES_GROUP.CHIN_CREASE],
  11: [WRINKLES_GROUP.NECK]
};

const FACE_LINES_GROUP_TITLE = {
  [WRINKLES_GROUP.FORHEAD]: 'wrinkleLocation.foreheadLines',
  [WRINKLES_GROUP.FROWN]: 'wrinkleLocation.frownLines',
  [WRINKLES_GROUP.CROWS_FEET]: 'wrinkleLocation.crowsFeet',
  [WRINKLES_GROUP.BUNNY]: 'wrinkleLocation.bunnyLines',
  [WRINKLES_GROUP.TEAR_TROUGHS]: 'wrinkleLocation.tearTroughs',
  [WRINKLES_GROUP.NASOLABIAL_FOLDS]: 'wrinkleLocation.nasolabialFolds',
  [WRINKLES_GROUP.VERTICAL_LIP]: 'wrinkleLocation.verticalLipLines',
  [WRINKLES_GROUP.MOUTH_FROWN]: 'wrinkleLocation.mouthFrown',
  [WRINKLES_GROUP.MARIONETTE]: 'wrinkleLocation.marionetteLines',
  [WRINKLES_GROUP.CHIN_CREASE]: 'wrinkleLocation.chinCrease',
  [WRINKLES_GROUP.NECK]: 'wrinkleLocation.neck'
};

const sortWrinklesGroups = (groupA, groupB) => groupA.groupNumber - groupB.groupNumber;

export default {
  name: 'SlidersSet',
  components: { VueCarousel, FaceLinesSlider },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentGroupIndex: 0,
      screenSize: 'small',
      scrollbar: null
    };
  },
  computed: {
    maxIndex() {
      return this.itemsListLength - 1;
    },
    visibleAtATime() {
      return SLIDES_PER_VIEW[this.screenSize];
    },
    isDesktopLayout() {
      return this.screenSize === 'large';
    },
    lineGroups() {
      return Object.entries(this.value)
        .filter(([, value]) => +value > 0)
        .map(([groupName, value]) => ({
          groupNumber: GROUP_NUMBER[groupName],
          groupTitle: FACE_LINES_GROUP_TITLE[groupName],
          value
        }))
        .sort(sortWrinklesGroups);
    },
    isEmpty() {
      return !some(this.value, wrinkleDepth => +wrinkleDepth > 0);
    },
    itemsListLength() {
      return this.lineGroups.length;
    }
  },
  watch: {
    lineGroups: 'updateScreenSize',
    // we need to update the scrollbar every time
    // the number of items in the list changes;
    // otherwise we will not see the scrollbar
    // until a user scrolls the list's content
    itemsListLength: 'updateScrollbar'
  },
  mounted() {
    this.setupResizeListener();
    this.setupPerfectScrollbar();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreenSize);
  },
  methods: {
    setupResizeListener() {
      this.updateScreenSize();

      window.addEventListener('resize', this.updateScreenSize);
    },
    updateScrollbar() {
      this.scrollbar.update();
    },
    async setupPerfectScrollbar() {
      // if we initialize the scrollbar right away then
      // it will not be visible until a user scrolls the content
      await this.$nextTick();

      if (this.isDesktopLayout) {
        this.scrollbar = new PerfectScrollbar(this.$refs.sliderSet, {
          suppressScrollX: true,
          wheelPropagation: false
        });
      }
    },
    onValueChange(groupNumber, value) {
      const wrinkles = {
        ...this.value,
        [GROUP_NAME[groupNumber]]: value
      };

      this.$emit('input', wrinkles);
    },
    updateCurrentItem(offset) {
      const nextIndex = this.currentGroupIndex + offset;

      this.currentGroupIndex = clamp(nextIndex, 0, this.maxIndex);
    },
    updateScreenSize() {
      const { size } = [
        { query: '(max-width: 599px)', size: 'small' },
        { query: '(min-width: 600px) and (max-width: 959px)', size: 'medium' },
        { query: '(min-width: 960px)', size: 'large' }
      ].find(({ query }) => doesMatchMedia(query));

      this.screenSize = size;
    }
  }
};
</script>

<style lang="scss">
.slider-set {
  .ps__rail-y {
    visibility: hidden;
    /* For the thumb to be visible event before a user scrolls the content */
    opacity: 0.6;

    &:focus,
    &:hover {
      .ps__thumb-y {
        width: 2px;
      }
    }
  }

  .ps__thumb-y {
    width: 2px;
    visibility: visible;
  }
}
</style>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.carousel {
  &__item {
    margin-right: 16px;
  }
}

.slider-set {
  &__empty-message {
    display: none;
    color: var(--u-color-grey-500);
  }
}

@media (min-width: $desktop-start) {
  .slider-set {
    display: flex;
    flex-direction: column;
    height: 400px;
    width: 223px;
    position: relative;

    &__empty-message {
      display: initial;
    }

    &__slider {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>

<template>
  <div class="face-lines-input">
    <div class="face">
      <face-lines-picker
        class="face__picker"
        :value="value"
        :gender="gender"
        @input="$emit('input', $event)"
      />
      <div class="face__hint u-typography-helvetica u-text u-text--xs">
        {{ $t('wrinkleLocation.selectOnFace') }}
      </div>
    </div>

    <sliders-set class="face-lines-input__sliders" :value="value" @input="$emit('input', $event)" />
  </div>
</template>

<script>
import FaceLinesPicker from '@/modules/questionnaire/new-design-components/questions/face-lines-input/FaceLinesPicker';
import SlidersSet from '@/modules/questionnaire/new-design-components/questions/face-lines-input/SlidersSet';

export default {
  name: 'FaceLinesInput',
  components: { FaceLinesPicker, SlidersSet },
  props: {
    value: {
      type: Object,
      required: true
    },
    gender: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes.scss';

.face-lines-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.face {
  &__picker {
    margin: auto;
  }

  &__hint {
    color: var(--u-color-grey-500);
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
  }
}

@media (min-width: $desktop-start) {
  .face-lines-input {
    flex-direction: row;
    justify-content: center;
    align-items: start;

    &__sliders {
      margin-left: 54px;
    }
  }

  .face {
    &__hint {
      margin: 24px 0 0 0;
    }
  }
}
</style>
